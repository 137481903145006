import { startCase } from 'lodash';
import { Button } from '../atoms';
import { Dropdown } from '../elements';

const TabBarItem = ({
  listButtonText,
  createButtonText,
  activeTab,
  setActiveTab,
  type,
  setType,
  menuOptions,
  filterOptions,
  menuText,
}) => {
  return (
    <div className="flex gap-4 items-center flex-wrap">
      {filterOptions && activeTab === 'listView' ? (
        <div className="flex-none">
          <button
            type="button"
            className="px-1 shadow-xl rounded-lg hover:bg-primary border border-transparent hover:border-transparent p-0 transition duration-400 focus:outline-none linear-gradient-bg text-black hover:bg-primary font-bold"
          >
            <Dropdown
              isSecondary
              menuText={startCase(type)}
              options={filterOptions.map((item) => ({
                id: item,
                title: startCase(item),
                onClick: () => setType(item),
              }))}
            />
          </button>
        </div>
      ) : (
        <>
          {listButtonText && (
            <div className="flex-none">
              <Button
                className="md:pl-6 md:pr-6 pl-4 pr-4"
                isActive={activeTab === 'listView'}
                onClick={() => setActiveTab('listView')}
              >
                {listButtonText}
              </Button>
            </div>
          )}
        </>
      )}

      <div className="flex-none">
        <Button
          className="md:pl-6 md:pr-6 pl-4 pr-4"
          isActive={activeTab === 'createView'}
          onClick={() => setActiveTab('createView')}
        >
          {createButtonText}
        </Button>
      </div>

      {menuOptions && activeTab === 'listView' && (
        <div className="flex-grow text-right">
          <Dropdown menuText={menuText} options={menuOptions} />
        </div>
      )}
    </div>
  );
};

export default TabBarItem;
