import { withFormik } from 'formik';
import * as Yup from 'yup';

import ButtonSecondary from '../atoms/ButtonSecondary';
import { TextInputGroup } from '../elements';

const formId = 'HomeSubscriptionForm';
const HomeSubscriptionForm = ({
  values,
  touched,
  errors,
  isSubmitting,
  handleSubmit,
  handleChange,
  handleBlur,
}) => {
  return (
    <form onSubmit={handleSubmit} id={formId} className="mt-6">
      <div className="grid lg:grid-cols-3 sm:grid-cols-3 gap-4">
        <div className="col-span-2">
          <TextInputGroup
            hasNotBorder
            className="pt-3 pb-3 rounded-t-lg rounded-b-lg"
            bgColor="liteInput"
            placeholder="Enter your Email"
            name="email"
            type="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email ? errors.email : undefined}
          />
        </div>
        <div>
          <ButtonSecondary
            size="medium"
            isActive
            type="submit"
            form={formId}
            isLoading={isSubmitting}
            className="font-quaternaryFontFamily"
          >
            Sign Up
          </ButtonSecondary>
        </div>
      </div>
    </form>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    email: '',
  }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required!'),
  }),

  handleSubmit: async (values, { setSubmitting, props }) => {
    await props.onSubmit(values).finally(() => {
      setSubmitting(false);
    });
  },
  displayName: formId, // helps with React DevTools
})(HomeSubscriptionForm);
