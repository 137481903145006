import { Fragment } from 'react';
import { useRouter } from 'next/router';
import { gql, useQuery } from '@apollo/client';
import Image from 'next/image';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Cookies from 'js-cookie';
import { Popover, Transition } from '@headlessui/react';
import { CgClose, CgMenuRightAlt } from 'react-icons/cg';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Dropdown from '../elements/Dropdown';
import { Button, Paragraph } from '../atoms';

import config from '../../utils/config';

const meQuery = gql`
  query headerMeData {
    me {
      email
      type
      profile {
        image
        firstName
        lastName
      }
    }
  }
`;

const navItems = [
  // { id: 1, url: '/', title: 'Home' },
  { id: 2, url: '#about', title: 'About' },
  { id: 3, url: '#contact', title: 'Contact' },
  // { id: 4, url: '#', title: 'Pricing' },
];

// const navItems = [];
const Header = ({ pageTitle, hasDashboard }) => {
  const router = useRouter();
  const isLoggedIn = useStoreState((state) => state.isLoggedIn.value);
  const setIsLoggedIn = useStoreActions((actions) => actions.isLoggedIn.toggle);
  const setUserData = useStoreActions((actions) => actions.user.setUser);

  const { data, error } = useQuery(meQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 15000,
  });
  const me = data && data.me ? data.me : {};

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserData(undefined);
    Cookies.remove('token');
    router.push('/');
  };
  const dropdownOptions = [
    {
      id: 1,
      title: 'Profile',
      onClick: () => router.push('/auth/account'),
    },
    { id: 2, title: 'Logout', onClick: () => handleLogout() },
  ];

  let title;
  if (router.asPath === '/dashboard') {
    title = 'Dashboard';
  } else if (router.asPath === '/dashboard/events') {
    title = 'Events';
  } else if (router.asPath === '/dashboard/workouts') {
    title = 'Workouts';
  } else if (router.asPath === '/dashboard/nutritions') {
    title = 'Nutritions';
  } else if (router.asPath === '/dashboard/live-videos') {
    title = 'Live Videos';
  } else if (router.asPath === '/dashboard/users') {
    title = 'Users';
  } else if (router.asPath === '/dashboard/subscriptions') {
    title = 'Subscriptions';
  } else if (router.asPath === '/auth/account') {
    title = 'Profile';
  } else {
    title = '';
  }
  return (
    <Popover
      className={`relative ${
        hasDashboard ? 'bg-white' : 'bg-secondary'
      } px-3 md:pt-4 pt-0`}
    >
      {({ open }) => (
        <>
          <div className="mx-auto container">
            <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
              <div className="flex items-center">
                {(title || pageTitle) && (
                  <Paragraph
                    fontWeight="bold"
                    size="xl"
                    className="md:mr-10 mr-4 md:block hidden capitalize"
                  >
                    {title || pageTitle}
                  </Paragraph>
                )}
                {!hasDashboard && (
                  <div className="flex justify-start lg:flex-auto -ml-6 py-2">
                    <a href="/">
                      <span className="sr-only">{config.siteName}</span>
                      <Image
                        src="/images/logo.png"
                        alt="logo"
                        width={200}
                        height={50}
                      />
                    </a>
                  </div>
                )}
                {/* : (
                  router.pathname !== '/dashboard/live/[id]' && (
                    <Button
                      onClick={() =>
                        router.push('/dashboard/live/new4546384686')
                      }
                      isActive
                      size="medium"
                    >
                      Go Live
                    </Button>
                  )
                )} */}
              </div>
              <div className="-mr-2 -mt-4 md:hidden">
                <Popover.Button
                  className={`bg-transparent rounded-md p-2 inline-flex items-center justify-center ${
                    router.asPath === '/' ? 'text-white' : 'text-black'
                  }`}
                >
                  <span className="sr-only">Open menu</span>
                  <CgMenuRightAlt className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              {!hasDashboard && (
                <Popover.Group
                  as="nav"
                  className="hidden md:flex flex-1 space-x-10 justify-end"
                >
                  <a
                    href="/"
                    className="font-medium text-white font-quaternaryFontFamily"
                  >
                    Home
                  </a>
                  {navItems.map((item) => (
                    <AnchorLink
                      key={item.id}
                      href={item.url}
                      className="font-medium text-white font-quaternaryFontFamily"
                    >
                      {item.title}
                    </AnchorLink>
                  ))}
                  {/* <a
                    href="/"
                    className="font-medium text-white font-quaternaryFontFamily"
                  >
                    Pricing
                  </a> */}
                </Popover.Group>
              )}

              {isLoggedIn && !error ? (
                <div
                  className={`hidden md:flex items-center justify-end ${
                    hasDashboard && 'md:flex-1'
                  }`}
                >
                  <Dropdown
                    options={dropdownOptions}
                    hideDropDownIcon
                    image={
                      me.profile && me.profile.image
                        ? me.profile.image
                        : '/images/avatar.png'
                    }
                    alt={
                      me.profile
                        ? `${me.profile.firstName} ${me.profile.lastName}`
                        : me.email
                    }
                    menuText={
                      me.profile
                        ? `${me.profile.firstName} ${me.profile.lastName}`
                        : me.email
                    }
                  />
                </div>
              ) : (
                <div className="hidden md:flex items-center justify-end">
                  <a
                    href="/auth/login"
                    className="ml-3 linear-gradient-bg-dark text-white hover:bg-primary font-bold shadow-xl font-quaternaryFontFamily rounded-md lg:px-12 md:px-8 px-2 py-3 focus:outline-none"
                  >
                    Login
                  </a>
                  {/* <AnchorLink
                    href="/auth/register"
                    className="ml-6 linear-gradient-bg text-black hover:bg-primary font-bold shadow-xl rounded-lg lg:px-8 px-2 py-3 focus:outline-none"
                  >
                    Sign Up
                  </AnchorLink> */}
                </div>
              )}
            </div>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="absolute top-0 inset-x-0 p-2 b-0 transition bg-secondary transform origin-top-right md:hidden z-10 shadow-xg"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-transparent divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div className="-mr-4">
                      <Image
                        src="/images/logo.png"
                        alt="logo"
                        width={200}
                        height={50}
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-white">
                        <span className="sr-only">Close menu</span>
                        <CgClose className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="py-6 px-5">
                  <div className="grid grid-cols-1 gap-y-4 gap-x-8 text-white">
                    <a href="/" className="has-text-weight-semibold">
                      Home
                    </a>
                    {navItems.map((item) => (
                      <AnchorLink
                        key={item.id}
                        href={item.url}
                        className="has-text-weight-semibold"
                      >
                        {item.title}
                      </AnchorLink>
                    ))}
                    <a href="#" className="has-text-weight-semibold">
                      Pricing
                    </a>
                  </div>
                  {isLoggedIn ? (
                    <div className="pt-10 pb-2">
                      <Button
                        isActive
                        size="medium"
                        onClick={handleLogout}
                        className="w-full"
                      >
                        Logout
                      </Button>
                    </div>
                  ) : (
                    <div className="flex pt-6 pb-4">
                      <AnchorLink
                        href="/auth/login"
                        className=" mr-6 linear-gradient-bg-dark text-white hover:bg-primary font-bold shadow-xl rounded-lg hover:bg-primary lg:px-8 px-6 py-3 focus:outline-none"
                      >
                        Login
                      </AnchorLink>
                      {/* <AnchorLink
                        href="/auth/register"
                        className="linear-gradient-bg text-black hover:bg-primary font-bold shadow-xl rounded-lg hover:bg-primary lg:px-8 px-6 py-3 focus:outline-none"
                      >
                        Sign Up
                      </AnchorLink> */}
                    </div>
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default Header;
