import Image from 'next/image';
import { HiDotsVertical } from 'react-icons/hi';
import { useRouter } from 'next/router';
import { Line } from 'react-chartjs-2';
import Swal from 'sweetalert2';
import Truncate from 'react-truncate';

import { AnchorLink, Heading, Paragraph } from '../atoms';
import { Dropdown } from '../elements';
import theme from '../../utils/theme';

const data = {
  labels: ['', '', '', '', '', ''],
  datasets: [
    {
      label: 'Users',
      data: [40, 59, 45, 81, 56, 76, 100],
      borderColor: theme.primary,
      backgroundColor: theme.primary,
      fill: false,
      tension: 0.1,
    },
  ],
};

const CardItem = ({
  item,
  path,
  showChart,
  handleRemove,
  handleUpdateStatus,
  hideMenuIcon,
}) => {
  const router = useRouter();
  const onDelete = () => {
    Swal.fire({
      title: 'Are you sure you want to delete this?',
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await handleRemove();
        Swal.fire('Deleted successfully!', '', 'success');
      }
    });
  };
  const dropdownOptions = [
    {
      id: 1,
      title: 'Edit',
      onClick: () => router.push(path),
    },
    {
      id: 3,
      title: item.status === 'active' ? 'Set disabled' : 'Set active',
      onClick: () =>
        handleUpdateStatus(item.status === 'active' ? 'notActive' : 'active'),
    },
    { id: 2, title: 'Delete', onClick: onDelete },
  ];
  return (
    <div className="bg-white relative border-primary border-2 rounded-2xl duration-700 hover:shadow-md">
      <AnchorLink href={path}>
        <div className="rounded-2xl rounded-b-none">
          {!item.image && item.video ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              className="rounded-t-2xl w-full "
              width="520"
              height="440"
              controls
            >
              <source src={item.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <Image
              className="rounded-t-2xl"
              src={item && item.image ? item.image : '/images/placeholder.jpg'}
              layout="responsive"
              alt={item.name}
              width={400}
              height={220}
              objectFit="cover"
            />
          )}
        </div>
        <div className="mb-9">
          <div className="mx-8 py-4 text-center">
            <Heading className="text-darkBlack font-primaryFontFamily text-3xl tracking-wide">
              {item.name}
            </Heading>
            <Paragraph size="base" className="text-light">
              <Truncate
                lines={2}
                ellipsis={
                  <span>
                    ...
                    <AnchorLink className="text-primary" href={path}>
                      More
                    </AnchorLink>
                  </span>
                }
              >
                {item.details || item.subTitle}
              </Truncate>
            </Paragraph>
          </div>
          {showChart && (
            <div className="px-10">
              <Line data={data} />
            </div>
          )}
        </div>
        <div className="bg-primaryLite py-2 text-center px-2 absolute bottom-0 right-0 left-0 rounded-b-2xl">
          <Paragraph
            size="lg"
            fontWeight="semibold"
            className="text-primary capitalize"
          >
            {item?.type || item?.level || item?.subTitle}
          </Paragraph>
        </div>
      </AnchorLink>
      {!hideMenuIcon && (
        <div className="absolute z-10 top-2 right-2">
          <Dropdown
            options={dropdownOptions}
            hideDropDownIcon
            menuText={<HiDotsVertical className="h-7 w-8 text-primary" />}
          />
        </div>
      )}
    </div>
  );
};

export default CardItem;
