import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// import { AiOutlineMail } from 'react-icons/ai';
// import { MdPhoneAndroid } from 'react-icons/md';
// import { IoLocationSharp } from 'react-icons/io5';

import { Heading, Paragraph } from '../atoms';
// import config from '../../utils/config';
import HomeSubscriptionForm from '../forms/HomeSubscriptionForm';
import SocialIcons from './SocialIcons';

const Container = styled.div``;

const infoItems = [
  {
    title: 'About',
    url: '#about',
  },
  {
    title: 'Features',
    url: '#features',
  },
];

const Footer = () => (
  <Container className="footer-2 bg-secondary text-white md:py-34 py-24 relative">
    <div className="container px-4 mx-auto">
      <div className="lg:flex gap-6">
        <div className="md:w-1/3">
          <Heading className="text-2xl font-semibold mb-8 font-tertiaryFontFamily">
            Information
          </Heading>
          {infoItems.map((item) => (
            <div key={item.url} className="my-5">
              <AnchorLink
                href={item.url}
                className="hover:text-primary font-extralight font-quaternaryFontFamily"
              >
                {item.title}
              </AnchorLink>
            </div>
          ))}
        </div>
        {/* <div className="md:w-1/3 lg-w-2/5">
          <Heading className="text-2xl font-semibold my-8 sm:mt-8 lg:my-0 font-tertiaryFontFamily">
            Contact
          </Heading>

          <div className="my-5 flex">
            <IoLocationSharp className="h-8 w-8 mr-2" />
            <Span
              fontWeight="extralight"
              className="text-base hover:text-primary font-quaternaryFontFamily"
            >
              {config.location}
            </Span>
          </div>
          <div className="my-5 flex">
            <MdPhoneAndroid className="h-8 w-8 mr-2" />
            <AnchorLink
              href={`tel:${config.telephone}`}
              className="hover:text-primary"
            >
              {config.telephone}
            </AnchorLink>
          </div>
        </div> */}
        <div className="xl:w-full">
          <Heading className="text-2xl font-semibold lg:my-0 my-8 sm:mt-8 lg:mt-0 font-tertiaryFontFamily">
            Subscribe to our newsletter
          </Heading>
          <Paragraph size="base" className="pb-2 lg:pt-8 pt-0">
            Stay up to date with our latest developments, app enhancements and
            health tech updates.
          </Paragraph>
          <HomeSubscriptionForm />
          <SocialIcons
            socialData={{
              twitter: '/',
              facebook: '/',
              linkedin: '/',
            }}
          />
        </div>
      </div>
    </div>
    <div className="absolute -top-16 right-16">
      <img src="/icons/ball.svg" alt="ball" className="h-30 w-30" />
    </div>
  </Container>
);

export default Footer;
