import PropTypes from 'prop-types';

const ButtonSecondary = ({
  children,
  size,
  isLoading,
  isActive,
  isSecondary,
  className,
  active,
  isLite,
  ...props
}) => {
  return (
    <button
      type="button"
      disabled={isLoading}
      className={`shadow-xl rounded-lg hover:border-transparent px-6 transition duration-400 focus:outline-none ${
        size === 'medium' ? 'py-3' : 'lg:py-5 py-3'
      } ${
        isActive
          ? 'linear-gradient-bg text-white linear-gradient-bg-dark hover:bg-primary font-bold'
          : 'text-textLighter hover:text-black bg-buttonLite'
      } ${isLoading ? 'animate-pulse ' : ''} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

ButtonSecondary.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  isActive: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
};

ButtonSecondary.defaultProps = {
  children: '',
  isActive: false,
  size: 'large',
  onClick: undefined,
};

export default ButtonSecondary;
