import {
  AiOutlineTwitter,
  AiFillFacebook,
  AiFillLinkedin,
} from 'react-icons/ai';
import { AnchorLink } from '../atoms';

const socialNetworks = ({ twitter, facebook, linkedin }) => {
  const items = [];
  if (twitter) {
    items.push({
      id: 1,
      icon: <AiOutlineTwitter className="h-10 w-10" />,
      link: twitter,
    });
  }
  if (facebook) {
    items.push({
      id: 2,
      icon: <AiFillFacebook className="h-10 w-10" />,
      link: facebook,
    });
  }
  if (linkedin) {
    items.push({
      id: 3,
      icon: <AiFillLinkedin className="h-10 w-10" />,
      link: linkedin,
    });
  }

  return items;
};

const SocialIcons = ({ socialData }) => (
  <div className="flex mt-2">
    {socialNetworks({
      twitter: socialData.twitter,
      facebook: socialData.facebook,
      linkedin: socialData.linkedin,
    }).map((item) => (
      <AnchorLink href={item.link} key={item.id} className="mr-6 pt-6 md:pt-0">
        {item.icon}
      </AnchorLink>
    ))}
  </div>
);

export default SocialIcons;
